import * as React from 'react';
import { Link } from 'react-router-dom'

import PostList from '../components/postlist';

import {Columns,Column,Box,Title} from '../components/bulma.js';
import {HeaderImage} from '../components/pageelements.js';

export default function(props) {
	return <>
		<HeaderImage src="/images/villagers_header.png"/>
		<Columns>
			<Column is="8" className="content">
				<Box>
					<Title>About</Title>
					<p>
						The Villagers is a hub for all supporters groups of 
						the <a href="https://whitecapsfc.com/">Vancouver Whitecaps</a>. 
						It will be used to communicate within the groups, and between groups. 
						This is not a Whitecaps supporters group. 
						It is a place that will hopefully empower all supporters groups regardless of 
						size to support their team and their community how they want to support.
					</p>
					<p>
						The site will start small, and build based on the requests and needs of the groups. 
						If you'd like to get involved, <Link to="/register">Join now</Link> and see what it
						is all about!
					</p>
				</Box> 
			</Column>
			<Column is="4">
				<Box>
					<Title>News</Title>
					<PostList compact max={4}/>
				</Box>
			</Column>
		</Columns>
	</>
}
