import * as React from 'react';
import {useState, useEffect} from 'react';
import {Editor as Renderer, EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import produce from 'immer';
import AsyncButton from './asyncbutton';
import {Button} from './bulma';

import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {uploadImage} from '../store/images';

function blockRenderer(block) {
    const type = block.getType();
    if(type === 'atomic') {
        return {
            editable: false,
            component: (props) => {
                const entity = props.contentState.getEntity(props.block.getEntityAt(0)).getData();
                return <img src={entity.src} height={entity.height} width={entity.width}/>;
            }
        }
    }
}

enum SaveState {
    NONE,
    SAVING,
    ERROR
}

export default function RichText(props) {
    const [editorState,setEditorState] = useState(EditorState.createEmpty());
    const [editing, setEditing] = useState(false);
    const [imageFiles,setImageFiles] = useState<Array<{link:string,file:File}> >([]);
    const [saveState, setSaveState] = useState<SaveState>(SaveState.NONE);

    useEffect(() => {
        if(props.content) {
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(props.content))));
        }
    }, [props.content]);

    const options = ['inline', 'blockType', 'textAlign', 'fontSize', 'list', 'link', 'image'];
    if(!props.editable || !editing) {
        return <div>
            <Renderer editorState={editorState} onChange={setEditorState} readOnly={true} blockRendererFn={blockRenderer}/>        
            {props.editable && <Button className="is-primary" onClick={() => setEditing(true)}>Edit</Button>}
        </div>
    }
    function save() {
        const raw = convertToRaw(editorState.getCurrentContent());
        if(props.onSave) {
            (async function() {
                setSaveState(SaveState.SAVING);
                const convertedContent = await produce(raw, async (draft) => {
                    for(const entityId in raw?.entityMap) {
                        const entity = raw.entityMap[entityId];
                        if(entity.type === "IMAGE") {
                            const imageRef = imageFiles.find((imageRef) => imageRef.link === entity.data?.src);
                            if(imageRef) {
                                const newUrl = await uploadImage(imageRef.file);
                                draft.entityMap[entityId].data.src = newUrl;
                            }
                        }
                    }
                });
                props.onSave(convertedContent);
            })().then(() => {
                setSaveState(SaveState.NONE);
                setEditing(false);
            });
        }
    }

    async function addImage(file) {
        const link = URL.createObjectURL(file);
        setImageFiles([...imageFiles,{link,file}]);
        return {data:{link}};
    }

    return <div>
        <Editor toolbar={{options, image: {uploadCallback:addImage}}} editorState={editorState} onEditorStateChange={setEditorState}></Editor>
        <AsyncButton className="is-primary" onClick={save} working={saveState === SaveState.SAVING}>Save</AsyncButton>
    </div>
}