import * as React from 'react';

import {Link} from 'react-router-dom';

export default function Privacy(props)  {
    return <div className="content">
        <h1>Privacy Policy ("Policy")</h1>
        <h3>Last updated: February 21, 2020</h3>
        <p>
            Please read this Policy carefully before using 
            the ​<Link to="/">https://thevillagers.ca</Link> website (the "Service", "Website", or "site") operated by ​The Villagers Organization ("us", "we", or "our").
            It will explain how we use your data on our website, and your rights with that data.
        </p>
        <h3>Collection</h3>
        <p>
            Our site collects your name, and email address from you.  This data is directly supplied to us when you register for the website,
            and retained while you are a registered user of the site.
            We do not receive any of your data through indirect sources (other websites).
        </p>
        <h3>Use</h3>
        <p>
            We will use your information to manage your account, and send you relevant information from the site.
            All of this information is optional, and is controlled by you.
            Your data will not be shared with other companies or entities.
        </p>
        <h3>Security</h3>
        <p>
            All of your data is stored in secure facilities by Amazon AWS.  Access is restricted to only Administrators of the site,
            and all passwords are stored encrypted.
        </p>
        <h3>Rights</h3>
        <p>
            At any time, you may delete your account.  This will remove all of your personal information from the site,
            but not the content created with that account.  However, that content will not display your personal information.
        </p>
        <h3>Cookies</h3>
        <p>
            Cookies are only used for keeping you signed in to the site.  Deleting your cookies will require you to sign in again. 
        </p>
        <h3>Links</h3>
        <p>
            The Our Company website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.
        </p>
        <h3>Changes</h3>
        <p>
            We will regularly review and update this policy.  Any updates to this policy will be on this page.
        </p>
        <h3>Contact</h3>
        <p>
            For any additional questions, clarifications, or requests, please contact <a href="mailto:info@thevillagers.ca">mailto:info@thevillagers.ca</a>.
        </p>
    </div>

}