import {useCallback, useEffect} from 'react';
import create from 'zustand';
import produce from 'immer'
import {Minimal as GroupMinimal, Group} from '../../../common/src/types/groups';
import validateGroup from '../../../common/src/generated/validate-group';

import {getToken} from './users';

interface GroupStore {
    groupList: Array<GroupMinimal>;
    groups: Map<string, Group>;
    getAll: () => Promise<void>;
    get: (string) => Promise<void>;
    update: (Group) => Promise<void>
}

export const useStore = create<GroupStore>((set,get) => ({
    groupList: [],
    groups: new Map<string, Group>(),

    // TODO: Cache
    // used very infrequently.  Groups do not change often, and anyone changing them will likely just refresh the browser.
    getAll: async () => {
        const response = await fetch('https://api.thevillagers.ca/group');
        const groups = await response.json();
        if(groups.items) {
            const groupList = [];
            for(const group of groups.items) {
                if(validateGroup(group)) {
                    groupList.push(group);
                }
            }
            set({groupList});
        }
    },
    get: async (groupId) => {
        const response = await fetch(`https://api.thevillagers.ca/group/${groupId.toLowerCase()}`);
        const group = await response.json();
        const groups = {...get().groups, [groupId]: group};
        set({groups});
    },
    update: async (group) => {
        const response = await fetch(`https://api.thevillagers.ca/group/${group.groupId.toLowerCase()}`,{
            method: 'PUT',
            headers: {
                'Authorization' : getToken()
            },
            body: JSON.stringify(group)
        });
        await get().get(group.groupId);
    }
}));


export function useGroups() {
    const groups = useStore(state => state.groupList);
    const getGroups = useStore(useCallback(state => state.getAll, []));

    useEffect(() => {
        getGroups();
    }, []);

    return groups;
}

export function useGroup(groupId:string) {
    const group = useStore(useCallback(state => state.groups[groupId], [groupId]));
    const getGroup = useStore(useCallback(state => state.get, []));

    useEffect(() => {
        getGroup(groupId);
    }, [groupId]);

    return group;
}
