"use strict";
export const validate = validate57;
export default validate57;
const schema29 = {"properties":{"groupId":{"type":"string"},"name":{"type":"string"},"logo":{"type":"string"}},"optionalProperties":{"shortDescription":{"type":"string"},"description":{"type":"string"},"images":{"values":{"properties":{"name":{"type":"string"},"url":{"type":"string"}}}}}};

function validate57(data, {instancePath="", parentData, parentDataProperty, rootData=data}={}){
let vErrors = null;
let errors = 0;
let valid0 = false;
if(data && typeof data == "object" && !Array.isArray(data)){
valid0 = true;
var valid1;
if(data.groupId !== undefined){
const _errs0 = errors;
if(!(typeof data.groupId == "string")){
validate57.errors = [{instancePath:instancePath+"/groupId",schemaPath:"/properties/groupId/type",keyword:"type",params:{type: "string", nullable: false},message:"must be string"}];
return false;
}
var valid1 = _errs0 === errors;
}
else {
valid1 = false;
validate57.errors = [{instancePath,schemaPath:"/properties/groupId",keyword:"properties",params:{error: "missing", missingProperty: "groupId"},message:"must have property 'groupId'"}];
return false;
}
if(valid1){
if(data.name !== undefined){
const _errs1 = errors;
if(!(typeof data.name == "string")){
validate57.errors = [{instancePath:instancePath+"/name",schemaPath:"/properties/name/type",keyword:"type",params:{type: "string", nullable: false},message:"must be string"}];
return false;
}
var valid1 = _errs1 === errors;
}
else {
valid1 = false;
validate57.errors = [{instancePath,schemaPath:"/properties/name",keyword:"properties",params:{error: "missing", missingProperty: "name"},message:"must have property 'name'"}];
return false;
}
if(valid1){
if(data.logo !== undefined){
const _errs2 = errors;
if(!(typeof data.logo == "string")){
validate57.errors = [{instancePath:instancePath+"/logo",schemaPath:"/properties/logo/type",keyword:"type",params:{type: "string", nullable: false},message:"must be string"}];
return false;
}
var valid1 = _errs2 === errors;
}
else {
valid1 = false;
validate57.errors = [{instancePath,schemaPath:"/properties/logo",keyword:"properties",params:{error: "missing", missingProperty: "logo"},message:"must have property 'logo'"}];
return false;
}
if(valid1){
var valid2;
if(data.shortDescription !== undefined){
const _errs3 = errors;
if(!(typeof data.shortDescription == "string")){
validate57.errors = [{instancePath:instancePath+"/shortDescription",schemaPath:"/optionalProperties/shortDescription/type",keyword:"type",params:{type: "string", nullable: false},message:"must be string"}];
return false;
}
var valid2 = _errs3 === errors;
}
else {
valid2 = true;
}
if(valid2){
if(data.description !== undefined){
const _errs4 = errors;
if(!(typeof data.description == "string")){
validate57.errors = [{instancePath:instancePath+"/description",schemaPath:"/optionalProperties/description/type",keyword:"type",params:{type: "string", nullable: false},message:"must be string"}];
return false;
}
var valid2 = _errs4 === errors;
}
else {
valid2 = true;
}
if(valid2){
if(data.images !== undefined){
let data5 = data.images;
const _errs5 = errors;
let valid3 = false;
if(data5 && typeof data5 == "object" && !Array.isArray(data5)){
var valid4 = true;
for(const key0 in data5){
let data6 = data5[key0];
const _errs6 = errors;
let valid5 = false;
if(data6 && typeof data6 == "object" && !Array.isArray(data6)){
valid5 = true;
var valid6;
if(data6.name !== undefined){
const _errs7 = errors;
if(!(typeof data6.name == "string")){
validate57.errors = [{instancePath:instancePath+"/images/" + key0.replace(/~/g, "~0").replace(/\//g, "~1")+"/name",schemaPath:"/optionalProperties/images/values/properties/name/type",keyword:"type",params:{type: "string", nullable: false},message:"must be string"}];
return false;
}
var valid6 = _errs7 === errors;
}
else {
valid6 = false;
validate57.errors = [{instancePath:instancePath+"/images/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"),schemaPath:"/optionalProperties/images/values/properties/name",keyword:"properties",params:{error: "missing", missingProperty: "name"},message:"must have property 'name'"}];
return false;
}
if(valid6){
if(data6.url !== undefined){
const _errs8 = errors;
if(!(typeof data6.url == "string")){
validate57.errors = [{instancePath:instancePath+"/images/" + key0.replace(/~/g, "~0").replace(/\//g, "~1")+"/url",schemaPath:"/optionalProperties/images/values/properties/url/type",keyword:"type",params:{type: "string", nullable: false},message:"must be string"}];
return false;
}
var valid6 = _errs8 === errors;
}
else {
valid6 = false;
validate57.errors = [{instancePath:instancePath+"/images/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"),schemaPath:"/optionalProperties/images/values/properties/url",keyword:"properties",params:{error: "missing", missingProperty: "url"},message:"must have property 'url'"}];
return false;
}
if(valid6){
for(const key1 in data6){
if((key1 !== "name") && (key1 !== "url")){
validate57.errors = [{instancePath:instancePath+"/images/" + key0.replace(/~/g, "~0").replace(/\//g, "~1")+"/" + key1.replace(/~/g, "~0").replace(/\//g, "~1"),schemaPath:"/optionalProperties/images/values",keyword:"properties",params:{error: "additional", additionalProperty: key1},message:"must NOT have additional properties"}];
return false;
break;
}
}
}
}
}
if(!valid5){
validate57.errors = [{instancePath:instancePath+"/images/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"),schemaPath:"/optionalProperties/images/values/properties",keyword:"properties",params:{type: "object", nullable: false},message:"must be object"}];
return false;
}
var valid4 = _errs6 === errors;
if(!valid4){
break;
}
}
valid3 = valid4;
}
else if(!valid3){
validate57.errors = [{instancePath:instancePath+"/images",schemaPath:"/optionalProperties/images/values",keyword:"values",params:{type: "object", nullable: false},message:"must be object"}];
return false;
}
var valid2 = _errs5 === errors;
}
else {
valid2 = true;
}
if(valid2){
for(const key2 in data){
if((((key2 !== "groupId") && (key2 !== "name")) && (key2 !== "logo")) && (((key2 !== "shortDescription") && (key2 !== "description")) && (key2 !== "images"))){
validate57.errors = [{instancePath:instancePath+"/" + key2.replace(/~/g, "~0").replace(/\//g, "~1"),schemaPath:"",keyword:"properties",params:{error: "additional", additionalProperty: key2},message:"must NOT have additional properties"}];
return false;
break;
}
}
}
}
}
}
}
}
}
if(!valid0){
validate57.errors = [{instancePath,schemaPath:"/properties",keyword:"properties",params:{type: "object", nullable: false},message:"must be object"}];
return false;
}
validate57.errors = vErrors;
return errors === 0;
}
