import * as React from 'react';
import {useState} from 'react';

import { Link as RouterLink } from 'react-router-dom'
import { useStore as useUsersStore, checkVerified, useUser } from '../store/users';

import MessageBox from './messagebox';

import {ExclamationCircleIcon} from '@heroicons/react/solid';

enum LoginState {
	Closed,
	Show,
	LoggingIn,
	Error
}

export default function NavBar(props):JSX.Element {
	const user = useUser();
	const login = useUsersStore(state => state.login);
	const logout = useUsersStore(state => state.logout);

	const [burgerActive, setBurgerActive] = useState<boolean>(false);
	const burgerClasses = burgerActive ? 'is-active' : '';

	// Login
	const [loginState,setLoginState] = useState<LoginState>(LoginState.Closed);
	const [email,setEmail] = useState('');
	const [password,setPassword] = useState('');

	const attributes = (user || {}).attributes || {};

	async function handleLogin() {
		setLoginState(LoginState.LoggingIn);
		try {
			await login(email,password);
			setLoginState(LoginState.Closed);
		}
		catch(e) {
			setLoginState(LoginState.Error);
		}
	}

	function clearLogin(): void {
		setEmail('');
		setPassword('');
		setLoginState(LoginState.Closed);
	}

	function checkLogin(e) {
		if(e.keyCode == 13 || e.which == 13) {
			handleLogin();
		}
	}

	return <>
		<nav className="navbar is-dark is-fixed-top">
			<div className="navbar-brand">
				<RouterLink className="navbar-item" to="/"><img src="/images/villagers_logo.svg"/>&nbsp;thevillagers.ca</RouterLink>
				<span className={`navbar-burger burger ${burgerClasses}`} data-target="navbarMenuHeroA" onClick={() => setBurgerActive(!burgerActive)}>
					<span></span>
					<span></span>
					<span></span>
				</span>
			</div>
			<div id="navbarMenuHeroA" className="navbar-menu">
				<div className="navbar-start">
				</div>
				<div className="navbar-end">
					{false && <RouterLink className="navbar-item" to="/groups">Groups</RouterLink>}
					{false && <span className="navbar-item">
						{!user && 
						<a className="button is-dark is-inverted" onClick={() => setLoginState(LoginState.Show)}>
							<span>Sign In</span>
						</a>}
						{user && 
							<div className="navbar-item has-dropdown is-hoverable">
								<a className="navbar-link">
									{attributes.name}
									{!checkVerified(user) && <>&nbsp;<i className="icon has-text-warning"><ExclamationCircleIcon/></i></>}
								</a>
								<div className="navbar-dropdown is-primary">
									{!checkVerified(user) && <RouterLink to="/verify" className="navbar-item">Not Verified&nbsp;<i className="icon has-text-warning"><ExclamationCircleIcon/></i></RouterLink>}
									<RouterLink to="/profile" className="navbar-item">Profile</RouterLink>
									<a className="navbar-item" onClick={() => logout()}>Sign Out</a>
								</div>
							</div>
						}
					</span>}
				</div>
			</div>
		</nav>
		<MessageBox title="Sign in" show={loginState != LoginState.Closed} busy={loginState==LoginState.LoggingIn} onOk={() => handleLogin()} onCancel={() => clearLogin()}>
			<p className="">
				Please enter your email and password you used to register for the site.
				If you are not yet registered, you can <RouterLink to="/register" onClick={() => setLoginState(LoginState.Closed)}>Register Here</RouterLink>
			</p>
			{ loginState == LoginState.Error && 
				<p className="notification is-danger">Invalid E-Mail or password</p>
			}
			<br/>
			<div className="field">
				<label className="label">E-Mail</label>
				<div className="control">
					<input type="text" className="input" placeholder="Enter your email address" onChange={(e) => setEmail(e.target.value)} onKeyPress={(e) => checkLogin(e)} />
				</div>
			</div>
			<div className="field">
				<label className="label">Password</label>
				<div className="control">
					<input type="password" className="input" placeholder="Enter your password" onChange={(e) => setPassword(e.target.value)} onKeyPress={(e) => checkLogin(e)} />
				</div>
			</div>
			<p>This site uses cookies.  By logging in, you agree to our <RouterLink to="/privacy" target="_blank">Privacy Policy</RouterLink>.</p>
			<p><RouterLink to="/forgot" onClick={() => setLoginState(LoginState.Closed)}>Forgot your password?</RouterLink></p>
    	</MessageBox>
	</>

}

