import * as React from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';

import MessageBox from '../components/messagebox';
import Field from '../components/field';

import {useStore, validateName, validateEmail, validatePassword} from '../store/users.js';


enum RegisterState {
    None,
    Registering,
    ShowConditions,
    Complete,
    UnknownError
}

export default function RegisterPage(props) {
    const [name,setName] = useState<string>('');
    const [email,setEmail] = useState<string>('');
    const [password,setPassword] = useState<string>('');
    const [passwordConfirm,setPasswordConfirm] = useState<string>('');


    const [registerState,setRegisterState] = useState<RegisterState>(RegisterState.None);

    const user = useStore(state => state.user);
    const createUser = useStore(state => state.create);

    async function register() {
        if(allValid()) {
            setRegisterState(RegisterState.Registering);
            try {
                await createUser(name, email, password);
                setRegisterState(RegisterState.Complete);
            }
            catch(e) {
                setRegisterState(RegisterState.UnknownError);
            }
        }
    }

    function allValid() {
        return validateName(name) 
            && validateEmail(email) 
            && validatePassword(password)
            && password == passwordConfirm;
    }

    if(user) {
        return <>
            <section className="section content">
                <h1>Logged In</h1>
                You are already logged in.  You can either sign out to create a new account, or modify your 
                current <Link to="/profile">Profile</Link>.
            </section>
        </>
    }

    if(registerState == RegisterState.Complete) {
        return <>
            <section className="section content">
                <h1>Success!</h1>
                <p>Registration successful. You may now go back to the <Link to="/">Main Page</Link> and Sign in.</p>
                <p>Your access to the site will be restricted until you verify your email.  You should receive an email from us in a few minutes</p>
            </section>
        </>
    }
    return <>
        <section className="section">
            <p>All fields marked with an astrix (*) are mandatory</p>
            { registerState == RegisterState.UnknownError && <p className="notification is-danger">
                There was an error registering. Please try again, or contact us at <a href="mailto:help@thevillagers.ca">help@thevillagers.ca</a>
            </p> }
            { false && <p className="notification is-danger">
                This email has already been used to register.  Did you <Link to="/forgot">forget your password?</Link>
            </p> }
            <Field label="Name *" 
                onChange={(e) => setName(e.target.value)} 
                isError={!validateName(name)}>
                This should be your real name, especially if you intend to make any posts.
            </Field>
            <Field label="E-Mail *"
                onChange={(e) =>  setEmail(e.target.value)} 
                isError={!validateEmail(email)}>
                Your E-Mail will be used to sign in. It can optionally be used to contact you.
            </Field>
            <Field label="Password *" type="password"
                onChange={(e) => setPassword(e.target.value)} 
                isError={!validatePassword(password)}
                isWarn={!validatePassword(password)}>
                The password must be at least 8 characters in length.  It must contain at least one uppercase character, one lower case character, one number, and one special character.
            </Field>
            <Field label="Password (Again) *" type="password"
                onChange={(e) => setPasswordConfirm(e.target.value)} 
                isError={password != passwordConfirm || !validatePassword(password)}>
                Both passwords must match.
            </Field>
            { registerState != RegisterState.Registering && 
                <button className="button is-primary"
                    disabled={!allValid()}
                    onClick={() => setRegisterState(RegisterState.ShowConditions)}>Register</button> }
            { registerState == RegisterState.Registering && <button disabled className="button is-primary"><i className="icon fa fa-spinner fa-spin"></i></button>}
            <br/>
            <p>
                Once you have successfully signed in you can join groups. Groups may require authorization before
                you can join them.
            </p>
        </section>
        <MessageBox title="Terms and Conditions" show={registerState == RegisterState.ShowConditions} onOk={() => register()} onCancel={() => setRegisterState(RegisterState.None)}>
            By continuing, you agree to accept the <Link to="/tac" target="_blank">Terms and Conditions</Link> and <Link to="/privacy" target="_blank">Privacy Policy</Link>.
        </MessageBox>

    </>
}
