import React from 'react';

import {Link} from 'react-router-dom';

import {getGroups,getGroup} from '../store/groups.js';
import {getPosts} from '../store/posts.js';
import {refreshLogin} from '../store/user.js';
import {Card, Column, Columns, Media} from '../components/bulma.js';

class PostListClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        }
    }
    componentDidMount() {
        this.setState({user: undefined, group: undefined});
    }
	componentDidUpdate() {
        const userId = (this.props.user || {}).userId;
        if(this.state.user != userId || this.state.group != this.props.group) {
            this.setState({user: userId, group: this.props.group, loading: true});
            this.props.refreshLogin()
            .then(() => {
                Promise.all([this.props.getPosts(this.props.group),this.props.getGroups()])
                .then(() => {
                    this.setState({loading: false});
                    if(this.props.group && this.props.group != 'all') {
                        this.props.getGroup(this.props.group);
                    }
                })
                .catch((err) => {
                    this.setState({loading:false, error: true})
                })
            })
        }
	}
	render() {
        // TODO: make posts editable
        // TODO: make posts deletable
        var group = this.props.group || 'all';
        var posts = this.props.posts[group];
        var truncatedList = false;
        if(!posts || posts.length == 0) {
            if(this.state.loading) {
                return <div><i className="icon fa fa-spinner fa-spin"></i></div>
            }
            if(this.state.error) {
                return <div className="container content">
                    There was an error loading posts.  Please check back!
                </div>
            }
            return <div className="container content">
                There are no posts right now.  Please check back!
            </div>
        }
        if(this.props.max) {
            var max = parseInt(this.props.max);
            if(max != null && !isNaN(max)) {
                if(max < posts.length) {
                    posts = posts.slice(0,max);
                    truncatedList = true;
                }
            }
        }
		return <>
            {posts.map((post, iPost) => {
                var group = {};
                if(this.props.groups) {
                    group = this.props.groups[post.groupId] || {};
                }
                return <Card key={iPost} header={post.title}>
                    <Media left={
                        <img src={group.logo} className="image is-64x64"/>
                    }>
                        <Markdown content={post.body}/>
                    </Media>
                    <div className="is-size-7 has-text-right">Posted on {new Date(post.created).toLocaleString()}</div>
                </Card>
            })}
		</>
	}
}

export default function PostList(props):JSX.Element {
    return <div>Coming Soon...</div>
}
