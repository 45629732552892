import * as React from 'react';

export default function LegalPage(props) {
	return <>
		<section className="section">
			{props.component && <props.component/>}
		</section>
	</>
}

