import React from 'react';

export default function BasicField(props) {
    var type = props.type || 'text';
    if(type == 'checkbox') {
        return <div className="field">
        {props.label && <label className="label">
        <input type={type} disabled={props.disabled} checked={props.value} className={inputClasses} placeholder={props.placeholder} onChange={(evt) => {
            props.onChange(evt);
            }}/>
            {props.label}
        </label>}
        {!props.label && <input type={type}
            disabled={props.disabled}
            checked={props.value} 
            className={inputClasses} 
            placeholder={props.placeholder} 
            onChange={(evt) => {
                props.onChange(evt);
            }}/>}
        {props.children && <p className={helpClasses}>{props.children}</p>}
    </div>
    }
    var helpClasses = 'help';
    var inputClasses = 'input';
    if(props.isError) {
        helpClasses += ' is-danger';
        inputClasses += ' is-danger';
    }
    else if(props.isWarn) {
        helpClasses += ' is-warning';
        inputClasses += ' is-warning';
    }
    var filteredProps = Object.assign({},props);
    delete filteredProps.type;
    delete filteredProps.isError;
    delete filteredProps.isWarn;
    delete filteredProps.label;
    delete filteredProps.children;
    delete filteredProps.className;
    delete filteredProps.rows;

    var control = props.control;
    if(!control && type == 'textarea') {
        control = <textarea className={(props.className || '') + ' textarea ' + inputClasses} rows={props.rows || 3} {...filteredProps}></textarea>
    }
    if(!control) {
        control = <input type={type} className={(props.className || '') + ' ' + inputClasses} {...filteredProps}/>;
    }
    return <div className="field">
        {props.label && <label className="label">{props.label}</label>}
        <div className="control">
            {control}
        </div>
        {props.children && <p className={helpClasses}>{props.children}</p>}
    </div>
}