import * as React from 'react';

import {Link} from 'react-router-dom';

export default function TermsAndConditions(props) {
    return <div className="content">
        <h1>Terms and Conditions ("Terms")</h1>
        <h3>Last updated: February 21, 2020</h3>
        <p>
            Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using 
            the ​<Link to="/">https://thevillagers.ca</Link> website (the "Service") operated by ​The Villagers Organization ("us", "we", or "our").
        </p>
        <p>
            Your access to and use of the Service is conditioned on your acceptance of and compliance withthese Terms. These Terms apply to all visitors, users and others who access or use the Service.
            By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service. 
        </p>
        {false && <>
        <h5>Purchases</h5>
        <p>
        If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, withoutlimitation, your ...
        </p>
        </>}
        <h5>Content</h5>
        <p>
        You are responsible for your use of the Services and for any Content you provide, including 
        compliance with applicable laws, rules, and regulations. 
        Any use or reliance on any Content or materials posted via the Services or obtained 
        by you through the Services is at your own risk. We do not endorse, support, represent or
        guarantee the completeness, truthfulness, accuracy, or reliability of any Content or
        communications posted via the Services or endorse any opinions expressed via the Services.
        You understand that by using the Services, you may be exposed to Content that might be offensive,
        harmful, inaccurate or otherwise inappropriate, or in some cases, postings that have been
        mislabeled or are otherwise deceptive. All Content is the sole responsibility of the person who
        originated such Content. We may not monitor or control the Content posted via the Services and,
        we cannot take responsibility for such Content.
        </p>
        <p>
        You retain your rights to any Content you submit, post or display on or through the Services.
        By submitting, posting or displaying Content on or through the Services, you grant us a worldwide, 
        non-exclusive, royalty-free license (with the right to sublicense) to use, process, 
        adapt, modify, publish, transmit, display and distribute such Content on the Service. 
        This license authorizes us to make your Content available to the rest of the world and to let others do the same. 
        Such additional uses by us is made with no compensation paid to you with respect to 
        the Content that you submit, post, transmit or otherwise make available through the 
        Services as the use of the Services by you is hereby agreed as being sufficient compensation 
        for the Content and grant of rights herein.
        </p>
        <p>
        You represent and warrant that you have, or have obtained, all rights, licenses, consents, permissions, 
        power and/or authority necessary to grant the rights granted herein for any Content that you submit, 
        post or display on or through the Services. You agree that such Content will not contain material 
        subject to copyright or other proprietary rights, unless you have necessary permission or are otherwise 
        legally entitled to post the material and to grant thevillagers.ca the license described above.
        </p>
        <p>
            The Villagers also reserves the right to delete any content that, regardless of viewpoint, is 
            unrelated to the limited purpose of The Villagers Website. We also reserve the right to remove 
            content from The Villagers Website when that content, in the organizations sole discretion is:
        </p>
        <ul>
            <li>Potentially libelous</li>
            <li>Encouraging or constituting intimidating, harassing, or discriminatory conduct</li>
            <li>Containing personal attacks, insults, profane, name-calling, or threatening language</li>
            <li>Obscene or sexually explicit in nature</li>
            <li>Hateful or mean-spirited</li>
            <li>Plagiarized material or material that potentially violates intellectual property rights</li>
            <li>Private, personal information published without consent</li>
            <li>Commercial promotions or spam</li>
            <li>Off topic or that link to material that is off topic</li>
            <li>Embedded images from external sources</li>
            <li>Violating any law or promoting the violation of any law</li>
            <li>Made by a person masquerading as someone else</li>
            <li>Someone else’s image or content without their express permission</li>
        </ul>
        <p>
            Additionally, we reserve the right to terminate a person’s ability to post content or 
            otherwise participate in the organization when the person has posted any of the above 
            listed inappropriate content. 

        </p>
        <h3>Disclaimer</h3>
        <p>
            The Villagers assumes no liability for any damages or loss of any kind that might arise from the use of, 
            misuse of or the inability to use the Villagers page and/or the materials contained on the page. 
            The Villagers also assumes no liability for improper or incorrect use of materials or information 
            contained on the page. All materials that appear on the Villagers Website are distributed and 
            transmitted "as is," without warranties of any kind, either express or implied, and subject to the 
            terms and conditions stated in this disclaimer.
        </p>
        <p>
            As a condition using The Villagers Website, you agree that any dispute that cannot be resolved between 
            you and any other parties, and causes of action arising out of or connected with this Website shall be 
            resolved individually, without resort to any form of class action, exclusively, before a court of 
            competent jurisdiction located in the Province of British Columbia, which court shall apply the laws 
            of British Columbia without regard for rules of conflicts of law. In any such dispute, The Villagers 
            users shall, under no circumstances, be entitled to claim punitive, incidental, or consequential 
            damages, or any other damages, including attorneys' fees and you hereby waive all rights to have 
            damages multiplied or increased. 
        </p>

        <h5>Links To Other Web Sites</h5>
        <p>
            Our Service may contain links to third-party web sites or services that are not owned or controlled 
            by ​the Service.  The Site has no control over, and assumes no responsibility for, the content, 
            privacy policies, or practices of any third party web sites or services. You further acknowledge and
            agree that ​we shall not be responsible or liable, directly or indirectly, for any damage or loss caused
            or alleged to be caused by or in connection with use of or reliance on any such content, goods or 
            services available on or through any such web sites or services.
        </p>
        <h5>Changes</h5>
        <p>
            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. 
            If a revision is significant we will try to provide at least ​30 days notice prior to any new 
            terms taking effect. What constitutes a significant change will be determined at our sole discretion.
        </p>
        <h5>Contact Us</h5>
        <p>
            If you have any questions about these Terms, please contact us at <a href="mailto:info@thevillagers.ca">info@thevillagers.ca</a>.
        </p>
    </div>
}
