import * as React from 'react';
import {useState, useContext, useCallback} from 'react';
import Icon from '@mdi/react';
import {mdiSoccer} from '@mdi/js';

interface MessageBoxProps {
    show?: boolean;
    busy?: boolean;
    valid?: boolean;
    title?: string;
    className?: string;
    style?: object;
    children?: any;
    onOk: () => void;
    onCancel?: () => void;
}

export default function MessageBox(props:MessageBoxProps):JSX.Element {
    if(!props.show) {
        return null;
    }
    return <div className={"modal is-active " + props.className}>
        <div className="modal-background"></div>
        <div className="modal-card" style={props.style || {}}>
            {
                props.title && 
                <div className="modal-card-head">
                    <h1 className="title">{props.title}</h1>
                </div>
            }
            <section className="modal-card-body">
                {props.children}
            </section>
            <div className="modal-card-foot">
                <button disabled={props.busy || props.valid === false} className="button is-success" onClick={() => props.onOk()}>
                    { props.busy && <Icon path={mdiSoccer} spin/> }
                    Ok
                </button>
                { props.onCancel && 
                    <button disabled={props.busy} className="button is-danger" onClick={() => props.onCancel()}>Cancel</button>
                }
            </div>
        </div>
    </div>
}

type MessageBoxContextType = {
	okCallback: Function,
	hide: Function,
	busy: Function,
	idle: Function
}
const MessageBoxContext = React.createContext<MessageBoxContextType>({
    okCallback: () => {},
    hide: () => {},
    busy: () => {},
    idle: () => {}
});
export function useMessageBox(Component) {
	const [show,setVisible] = useState(false);
	const [busy,setBusy] = useState(false);
	const [context, setContext] = useState({
		okCallback: () => setVisible(false),
		hide: () => setVisible(false),
		busy: () => setBusy(true),
		idle: () => setBusy(false),
	});

	function onOk() {
		if(context.okCallback) {
			context.okCallback();
		}
	}

	return {
		show: () => setVisible(true),
		hide: () => setVisible(false),
		Component: useCallback((props) => {
			return <MessageBox show={show} busy={busy} {...props} onOk={onOk}>
				<MessageBoxContext.Provider value={context}>
					<Component/>
				</MessageBoxContext.Provider>
			</MessageBox>
		}, [show, busy, Component])
	}
}

export function useMessageBoxContext() {
    return useContext(MessageBoxContext);
}