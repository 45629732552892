import {useCallback, useEffect} from 'react';
import create from 'zustand';
import {ImageCreate, ImageUpload} from '../../../common/src/types/images';

import {getToken} from './users';

interface ImageStore {
}

export const useStore = create<ImageStore>((set,get) => ({
}));

export async function uploadImage(file:File):Promise<string> {
    const imageInfo:ImageCreate = {
        filename: file.name,
        size: file.size,
        type: file.type,
        permanent: false
    }
    const response = await fetch(`https://api.thevillagers.ca/image`,{
        method: 'POST',
        headers: {
            'Authorization' : getToken()
        },
        body: JSON.stringify(imageInfo)
    });
    const uploadInfo:ImageUpload = await response.json();
    await fetch(uploadInfo.uploadUrl, {
        method: 'PUT',
        body: file
    });
    return uploadInfo.imageUrl;
}