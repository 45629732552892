import * as React from 'react';
import {useCallback, useEffect, useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import produce from 'immer';

import PostList from '../components/postlist';
//import PostEdit from '../components/postedit';
//import Markdown from '../components/markdown';
import RichText from '../components/richtext';
import {Columns,Column,Box,Title,Button} from '../components/bulma';
import {HeaderImage} from '../components/pageelements';
import MessageBox, {useMessageBox, useMessageBoxContext} from '../components/messagebox';
import Field from '../components/field';
import ErrorBox from '../components/errorbox';
import Loader from '../components/loader';

import {useStore as useGroupStore, useGroup} from '../store/groups';
import {useStore as useUserStore, hasGroupPermissions} from '../store/users';

class GroupPageClass extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			group: null,
			postData: null,
			postState: 'hide'
		}
		this.editor = React.createRef();
	}

	componentDidMount() {
		this.setState({group:null});
	}

	componentDidUpdate() {
		if(!this.state.group) {
			this.props.refreshLogin()
			.then(() => {
				this.props.getGroup(this.props.match.params.group)
				.then((group) => {
					if(group) {
						this.setState({group});
					}
				})
			})
		}
		if(this.state.group && this.state.group.groupId != this.props.match.params.group) {
			this.setState({group:null});
		}
	}

	joinGroup() {
		if(!this.state.group || !this.props.user) {
			return;
		}
		this.setState({working: true, error: false});
		this.props.applyToGroup(this.state.group, this.state.applicationText)
		.then(() => {
			this.setState({working: false, error: false, applied: true, tryJoin: false, applied: true});
			this.props.refreshLogin();
		})
		.catch((err) => {
			this.setState({working: false, error: true});
		})
	}

	leaveGroup() {
		if(!this.state.group || !this.props.user) {
			return;
		}
		this.setState({working: true, error: false});
		this.props.leaveGroup(this.state.group.groupId)
		.then(() => {
			this.setState({working: false, tryLeave: false, group: null});
			this.props.refreshLogin();
		})
		.catch((err) => {
			this.setState({working: false, error: true});
		})
	}

	newPost() {
		var data = this.editor.current.getData();
		data.groupId = this.state.group.groupId;
		this.setState({postState:'working'})
		this.props.createPost(data)
		.then((result) => {
			this.setState({postState:'done'});
		})
		.catch((err) => {
			this.setState({postState:'error'})
		})
	}

	render() {
		const group = this.state.group || {};
		var actions = [];
		const member = hasGroupPermissions(user, group, 'member');
		const admin = hasGroupPermissions(user,group, 'admin');
		if(this.props.user) {
			if(!member) {
				actions.push({label: 'Join Group', flag: 'tryJoin'});
			}
			else if(admin) {
				actions.push({label: 'Settings', link: `/groups/${group.groupId}/settings`});
				actions.push({label: 'Manage Members', link: `/groups/${group.groupId}/manage`});
			}
			else {
				actions.push({label: 'Leave Group', flag: 'tryLeave'});
			}
		}
		// TODO: reusing the states between join and leave.
		return <>
			<HeaderImage src={group.banner}/>
			{actions.length > 0 && <>
					{actions.map((action) => {
						if (action.link) {
							return <Link key={action.label} to={action.link}>
								<Button className="is-primary">{action.label}</Button>
							</Link>
						}
						return <Button 
							key={action.label}
							className="is-primary"
							onClick={() => this.setState({[action.flag] : true})}
							key={action.label}>{action.label}</Button>
					})}
				</>
			}
			<Columns>
				<Column is="8" className="content">
					<Box>
						<Title>{group.name}</Title>
						<Markdown content={group.description}/>
					</Box>
				</Column>
				<Column is="4" className="content">
					<Box>
						{admin && <Button className="is-pulled-right is-primary" 
							onClick={() => this.setState({postState: 'show'})}
							>New Post</Button>}
						<Title>News</Title>
						<PostList group={group.groupId}/>
					</Box>
				</Column>
			</Columns>
			<MessageBox show={this.state.tryLeave} busy={this.state.working} onOk={() => this.leaveGroup()} onCancel={() => this.setState({tryLeave:false,error:false})}>
				<ErrorBox help={true} show={this.state.error}>There was an error trying to remove you from the group.</ErrorBox>
				Are you sure you want to leave this group?  Once you leave you will have to re-apply to take part in the group again.
			</MessageBox>
			<MessageBox show={this.state.tryJoin} busy={this.state.working} onOk={() => this.joinGroup()} onCancel={() => this.setState({tryJoin:false,applicationText:null})}>
				<div className="content">
					<p>
						Would you like to join this group and take part in their messages and events?  If you continue,
						your information will be forwarded to the group so they will decide if you are right for them.
					</p>
					{
						// TODO: Need error messaging
					}
					{(group.requirements === 'extra') && <>
							<p>
								In addition, this group has asked that you supply the following additional information below:
								<blockquote>{group.application}</blockquote>
							</p>
							<Field type="textarea" value={this.state.applicationText} onChange={(e) => this.setState({applicationText:e.target.value})}></Field>
						</>
					}
				</div>
			</MessageBox>
			<MessageBox show={this.state.applied} onOk={() => this.setState({applied:false})}>
				Your application has been submitted.  The group should get back to you soon.
			</MessageBox>
			<MessageBox title="New Post" show={this.state.postState != 'hide' && this.state.postState != 'done'} busy={this.state.postState == 'working'} onCancel={() => this.setState({postState: 'hide'})} onOk={() => this.newPost()}>
				<PostEdit ref={this.editor}/>
				<ErrorBox help show={this.state.postState=='error'}>There was an error creating the message.</ErrorBox>
			</MessageBox>
		</>
	}
}

function JoinDialog(props) {
	const group = {requirements: 'extra', application: 'aaa'};
	const [text, setText] = useState('');
	const context = useMessageBoxContext()

	context.okCallback = () => {
		console.log(text);
		context.hide();
	}

	return <div className="content">
		<p>
			Would you like to join this group and take part in their messages and events?  If you continue,
			your information will be forwarded to the group so they will decide if you are right for them.
		</p>
		{
			// TODO: Need error messaging
		}
		{(group.requirements === 'extra') && <>
				<p>
					In addition, this group has asked that you supply the following additional information below:
				</p>
				<blockquote>{group.application}</blockquote>
				<Field type="textarea" value={text} onChange={(e) => setText(e.target.value)}></Field>
			</>
		}
	</div>
}


export default function groupPage(props) {
	const user = useUserStore(state => state.user);
	const group = useGroup(props.groupId);
	const updateGroup = useGroupStore(useCallback(state => state.update, []));
	const JoinMessageBox = useMessageBox(JoinDialog);

	// TODO: stub data;

	var actions = [];
	if(user && group) {
		const member = false && hasGroupPermissions(user, group, 'member');
		const admin = hasGroupPermissions(user, group, 'admin');
		if(!member) {
			actions.push({label: 'Join Group', onClick: () => JoinMessageBox.show()});
		}
		else if(admin) {
			actions.push({label: 'Settings', link: `/groups/${group.groupId}/settings`});
			actions.push({label: 'Manage Members', link: `/groups/${group.groupId}/manage`});
		}
		else {
			actions.push({label: 'Leave Group', flag: 'tryLeave'});
		}
	}

	if(!group) {
		return <Loader/>
	}

	async function saveGroupDescription(desc) {
		updateGroup(produce(group, (draft) => {
			draft.description = JSON.stringify(desc);
		}));
	}

	return <>
		<HeaderImage src={group.banner || group.logo}/>
		{actions.length > 0 && <>
				{actions.map((action) => {
					if (action.link) {
						return <Link key={action.label} to={action.link}>
							<Button className="is-primary">{action.label}</Button>
						</Link>
					}
					return <Button 
						key={action.label}
						className="is-primary"
						onClick={() => action.onClick()}>{action.label}</Button>
				})}
			</>
		}
		<Columns>
			<Column is="8" className="content">
				<Box>
					<Title>{group.name}</Title>
					<RichText content={group.description} onSave={saveGroupDescription} editable={hasGroupPermissions(user,group,'writer')}/>
				</Box>
			</Column>
			<Column is="4" className="content">
				<Box>
					<Title>News</Title>
					<PostList group={group.groupId}/>
				</Box>
			</Column>
		</Columns>
		<JoinMessageBox.Component/>
	</>
}

