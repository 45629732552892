import React from 'react';

export default function ErrorBox(props) {
    var severity = props.severity || 'is-danger';
    if(!props.show) {
        return null;
    }
    return <div className={`notification ${severity}`}>
        {props.children}
        {props.help && <p>If the problem persists you can contact us at <a href="mailto:help@thevillagers.ca">help@thevillagers.ca</a></p>}
    </div>
}