import * as React from 'react';
import {useRef, useState, useEffect} from 'react';

import Icon from '@mdi/react';
import {mdiCog} from '@mdi/js';

import Field from '../components/field.js';

export default function Tifoizer(props) {
    const canvasRef = useRef<HTMLCanvasElement>();
    const mainRef = useRef<HTMLDivElement>();
    const [controlsVisible, showControls] = useState(true);
    const [image, setImage] = useState(null);
    // TODO: break up
    const [imageState, setImageState] = useState({
        offsetX: 0,
        offsetY: 0,
        scaleX: 1,
        scaleY: 1,
        screenWidth: 80,
        screenHeight: 60,
        totalWidth: '',
        totalHeight: '',
        imageWidth: 0,
        imageHeight: 0,
        showGrid: true,
        gridColor: 'red',
        gridX: '12',
        gridY: '12'
    });

    function setPartialImageState(newState) {
        setImageState(Object.assign({}, imageState, newState));
    }


    function componentDidMount() {
        window.addEventListener('resize', this.resize)
    }
    
    function componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }
    
    useEffect(() => {
        const canvas = canvasRef.current;
        if(!canvas) {
            return;
        }
        const ctx = canvas.getContext('2d');
        if(!ctx) {
            return;
        }
        ctx.canvas.width = canvas.clientWidth;
        ctx.canvas.height = canvas.clientHeight;
        ctx.fillStyle = 'black';
        ctx.fillRect(0,0,canvas.width,canvas.height);

        const scaleX = Math.max(0.001,imageState.totalWidth / imageState.screenWidth) * imageState.scaleX;
        const scaleY = Math.max(0.001,imageState.totalHeight / imageState.screenHeight) * imageState.scaleY;
        const width = ctx.canvas.width;
        const height = ctx.canvas.height;
        const offsetX = imageState.offsetX;
        const offsetY = imageState.offsetY;
        const ppiX = width / imageState.screenWidth;
        const ppiY = height / imageState.screenHeight;
        const gridX = parseFloat(imageState.gridX) || 12;
        const gridY = parseFloat(imageState.gridY) || 12;

        ctx.save();
        ctx.translate(offsetX*ppiX,offsetY*ppiY);

        if(image) {
            ctx.drawImage(image, 0, 0, width*scaleX, height*scaleY);
        }
        if(imageState.showGrid) {
            ctx.strokeStyle = imageState.gridColor || 'red';
            ctx.lineWidth = parseFloat(imageState.gridWidth || 1);
            ctx.beginPath();
            var maxIterations = 1000;
            var startX = Math.floor(imageState.screenWidth / gridX) * gridX * ppiX * -1;
            for(var lineX = startX; lineX < width + Math.abs(imageState.totalWidth*ppiX); lineX += gridX*ppiX) {
                if(maxIterations-- < 0) {
                    continue;
                }
                ctx.moveTo(lineX,-imageState.screenHeight*ppiY);
                ctx.lineTo(lineX,(imageState.totalHeight+imageState.screenHeight)*ppiY);
            }

            var startY = Math.floor(imageState.screenHeight / gridY) * gridY* ppiY * -1;
            for(var lineY = startY; lineY < height + Math.abs(imageState.totalHeight*ppiY); lineY += gridY*ppiY) {
                if(maxIterations-- < 0) {
                    continue;
                }
                ctx.moveTo(-imageState.screenWidth*ppiX,lineY);
                ctx.lineTo((imageState.totalWidth+imageState.screenWidth)*ppiX,lineY);
            }
            ctx.stroke();
        }
        ctx.restore();
    }, [canvasRef, image, imageState]);

    function loadFile(evt) {
        const element = evt.target;
        if(element && element.files && element.files.length > 0) {
            var img = new Image();
            var url = window.URL || window.webkitURL;
            var imgSrc = url.createObjectURL(element.files[0]);
            img.src = imgSrc;
            const self = this;
            new Promise((resolve,reject) => {
                img.onload = resolve;
            })
            .then((loadEvent) => {
                const image = loadEvent.target;
                var props:any = {imageWidth:image.width,imageHeight:image.height,offsetX:0,offsetY:0,scaleX:1,scaleY:1};
                if(imageState.totalWidth && imageState.totalHeight) {
                    // Do nothing.  Handled in the draw
                }
                else if (imageState.totalWidth) {
                    const aspect = image.width / image.height;
                    props.totalHeight = imageState.totalWidth / aspect;
                }
                else if (imageState.totalHeight) {
                    const aspect = image.width / image.height;
                    props.totalWidth = imageState.totalHeight * aspect;
                }
                else {
                    const aspect = image.width / image.height;
                    props.totalWidth = imageState.screenWidth;
                    props.totalHeight = props.totalWidth / aspect;
                }
                setImage(image);
                setImageState(props);
            });
        }
    }

    function canvasKey(event) {
        var stepSize = imageState.screenWidth / 10;
        if(event.shiftKey) {
            stepSize = 1;
        }
        if(event.keyCode == 61) { // = or +
            var rate = 0.1;
            return setPartialImageState({scaleX: imageState.scaleX + rate, scaleY: imageState.scaleY + rate});
        }
        if(event.keyCode == 173) { // - or _
            var rate = 0.1;
            return setPartialImageState({scaleX: Math.max(rate, imageState.scaleX - rate), scaleY: Math.max(rate, imageState.scaleY - rate)})
        }
        if(event.keyCode == 37) { // left
            return setPartialImageState({offsetX: Math.max(-imageState.totalWidth + stepSize, imageState.offsetX - stepSize)})
        }
        if(event.keyCode == 39) { // right 
            return setPartialImageState({offsetX: Math.min(imageState.screenWidth - stepSize, imageState.offsetX + stepSize)})
        }
        if(event.keyCode == 38) { // up 
            return setPartialImageState({offsetY: Math.max(-imageState.totalHeight + stepSize, imageState.offsetY - stepSize)})
        }
        if(event.keyCode == 40) { // down
            return setPartialImageState({offsetY: Math.min(imageState.screenHeight - stepSize, imageState.offsetY + stepSize)})
        }
    }

    function fullscreen() {
        if(mainRef.current) {
            mainRef.current.requestFullscreen();
        }
    }

    const mainStyle:React.CSSProperties = {
        position: 'fixed',
        top: '3em',
        left: '0px',
        bottom: '0px',
        right: '0px',
        zIndex: '100'
    };
    const controlStyle:React.CSSProperties = {
        position: 'fixed',
        left: '0px',
        top: '3em',
        right: '0px',
        zIndex: '101',
        background: 'white',
        padding: '2em'
    }
    const canvasStyle:React.CSSProperties = {
        position: 'relative',
        width:'100%',
        height:'100%'
    };
    const toggleStyle:React.CSSProperties = {
        position: 'fixed',
        zIndex: '102',
        right: '1em',
        top: '4em'
    }
    return <div ref={mainRef} style={mainStyle}>
        <div style={toggleStyle}>
            <button className="button is-primary is-small" onClick={() => showControls(!controlsVisible)}><Icon path={mdiCog}/></button>
        </div>
        {controlsVisible && 
        <div style={controlStyle}>
            <div className="columns">
                <div className="column is-6 content">
                    <div className="field">
                        <div className="file">
                        <label className="file-label">
                            <input className="file-input" type="file" onChange={(e) => loadFile(e)}/>
                            <span className="file-cta">
                                <span className="file-icon">
                                    <i className="fas fa-upload"></i>
                                </span>
                                <span className="file-label">
                                    Choose a file…
                                </span>
                            </span>
                        </label>
                        </div>
                    </div>
                    <div className="field">
                        <button className="button is-primary" onClick={() => fullscreen()}>Go Fullscreen</button>
                    </div>
                    {image &&
                    <div>
                        <p>Image: {imageState.imageWidth}x{imageState.imageHeight}</p>
                        <p>
                            Offset: {imageState.offsetX}x{imageState.offsetY}, 
                            Scale: {imageState.scaleX.toFixed(2)}x{imageState.scaleY.toFixed(2)}
                        </p>
                    </div>}

                </div>
                <div className="column is-6 content">
                    <p>
                        All measurements should be in cm or inches.  It doesn't matter which one, as long
                        as it is consistent.
                    </p>
                    <div className="columns">
                        <div className="column is-6">
                            <Field type="input" 
                                label="Screen Width" 
                                value={imageState.screenWidth} 
                                onChange={(e) => setPartialImageState({screenWidth:e.target.value})}
                            />
                        </div>
                        <div className="column is-6">
                        <Field type="input" 
                                label="Screen Height" 
                                value={imageState.screenHeight} 
                                onChange={(e) => setPartialImageState({screenHeight:e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-6">
                            <Field type="input" 
                                label="Total Width" 
                                value={imageState.totalWidth} 
                                onChange={(e) => setPartialImageState({totalWidth:e.target.value})}
                            />
                        </div>
                        <div className="column is-6">
                        <Field type="input" 
                                label="Total Height" 
                                value={imageState.totalHeight} 
                                onChange={(e) => setPartialImageState({totalHeight:e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-6">
                            <Field type="input" 
                                label="Grid Width" 
                                value={imageState.gridX} 
                                onChange={(e) => setPartialImageState({gridX:e.target.value})}
                            />
                        </div>
                        <div className="column is-6">
                        <Field type="input" 
                                label="Grid Height" 
                                value={imageState.gridY} 
                                onChange={(e) => setPartialImageState({gridY:e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-6">
                            <Field type="checkbox" 
                                label="Show Grid" 
                                value={imageState.showGrid} 
                                onChange={(e) => setPartialImageState({showGrid:e.target.checked})}
                            />
                        </div>
                        <div className="column is-6">
                        <Field type="input" 
                                label="Grid Color" 
                                value={imageState.gridColor} 
                                onChange={(e) => setPartialImageState({gridColor:e.target.value})}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        <canvas ref={canvasRef} tabIndex={0} style={canvasStyle} onKeyDown={(e) => canvasKey(e)}>
        </canvas>
    </div>
}

