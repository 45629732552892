import React from 'react';

export function Page(props) {
    return <div className="container page">
        {props.children}
    </div>
}

export function HeaderImage(props) {
    return <div className="header-image outer">
        <div className="header-image inner">
            {props.src && <img src={props.src} className="image header-image"/>}
            {!props.src && <div className="image header-image"/>}
        </div>
    </div>
}