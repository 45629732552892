import * as React from 'react';
import {Link} from 'react-router-dom';

export default function Footer(props) {
    return <div className="section has-background-dark has-text-light has-text-centered page-footer">
        <div><a href="https://thevillagers.ca">https://thevillagers.ca</a></div>
        <div>Contact Us - <a href="mailto:info@thevillagers.ca">info@thevillagers.ca</a></div>
        <Link to="/tac">Terms and Conditions</Link> - <Link to="/privacy">Privacy Policy</Link>
        <div>&copy;Copyright 2020</div>
    </div>
}