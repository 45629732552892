import * as React from 'react';
import {Link} from 'react-router-dom';

import {useStore as useGroupsStore} from '../store/groups.js';

import {Columns, Column, Section, Card, Media, Subtitle} from '../components/bulma.js';

export default function GroupsPage(props):JSX.Element {
	const groupList = useGroupsStore(store => store.groupList);

	let lines = [[]];
	groupList.forEach((group, iGroup) => {
		lines[lines.length-1].push(group);
		if(iGroup % 3 == 0 && iGroup > 0) {
			lines.push([]);
		}
	});

	return <>
		<Section className=" content">
			<h1>Groups</h1>
			<p>
				The Villagers is made up of all of the different supporter groups in Vancouver.
				Explore some of the groups below and see what they are all about!
			</p>
			{lines.map((line, iLine) => {
				return <Columns key={iLine}>
					{line.map((group, iGroup) => {
						return <Column key={iGroup} is="4">
							<Link to={`/${group.groupId}`}>
								<Card header={<Subtitle>{group.name}</Subtitle>} >
									<Media left={group.logo ? <img src={group.logo} className="image is-64x64"/> : null}>
										{group.short}
									</Media>
								</Card>
							</Link>
						</Column>
					})}
				</Columns>
			})}
		</Section>
	</>
}
