import {useCallback, useEffect} from 'react';
import create from 'zustand';
import {Auth} from '@aws-amplify/auth';

import {Minimal as GroupMinimal, Group} from '../../../common/src/types/groups';

interface UserStore {
    user: any;
    refresh: () => Promise<void>;
    login: (username:string,password:string) => Promise<void>;
    logout: () => Promise<void>;
    create: (name:string,email:string,password:string) => Promise<void>;
}

export const useStore = create<UserStore>((set,get) => ({
    user: null,
    refresh: async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            set({user});
        }
        catch (e) {
            // ignore failure
        }
    },
    login: async (email,password) => {
        const user = await Auth.signIn(email, password);
        set({user});
    },
    logout: async () => {
        await Auth.signOut();
        set({user: null})
    },
    create: async (name:string,email:string,password:string) => {
        try {
            const user = await Auth.signUp({
                username: email,
                password,
                attributes: {
                    email,
                    name
                }
            });
        }
        catch(e) {
            throw e;
        }
    }
}));

// awful name, but React convention
export function useUser() {
    const user = useStore(state => state.user);
    const refresh = useStore(useCallback(state => state.refresh, []));

    useEffect(() => {
        refresh();
    },[])
    return user;
}

export function checkVerified(user):boolean {
    return user?.attributes?.email_verified === true;
}

export function hasGroupPermissions(user, group: (Group|string), permission):boolean {
    let groupId = group;
    if(typeof(groupId) === 'object') {
        groupId = group.groupId;
    }
    const tokenPayload = user?.signInUserSession?.accessToken?.payload;
    if(tokenPayload && tokenPayload['cognito:groups']) {
        const groups:ReadonlyArray<string> = tokenPayload['cognito:groups'];
        if(groups.includes('admin')) { return true; }
        if(groups.includes(`${groupId}_${permission}`)) { return true; }
    }
    return false;
}

export function getToken():string | undefined {
    const user = useStore.getState()?.user;
    if(user) {
        return user?.signInUserSession?.accessToken?.jwtToken;
    }
}

export function validateName(name) {
	return name && name.length > 3;
}

export function validatePassword(password) {
    const passCheck = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
	return password && passCheck.test(password);
}

export function validateEmail(email) {
	if(!email) {
		return false;
	}
	const ampersandIndex = email.indexOf('@');
	return email && email.length > 3 && ampersandIndex != -1 && ampersandIndex != 0 && ampersandIndex != email.length-1;
}