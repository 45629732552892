import * as React from 'react';
import ReactDOM from 'react-dom';

import {Amplify} from 'aws-amplify';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Main from './pages/main';
import Register from './pages/register';
import Groups from './pages/groups';
import Group from './pages/group';
import Tifoizer from './pages/tifoizer';
import Legal from './pages/legal';

/*
import Forgot from './pages/forgot';
import UserProfile from './pages/userprofile';
*/
import Navbar from './components/navbar';
/*
import Verification from './pages/verification';
import Verified from './pages/verified';
import GroupSettings from './pages/groupsettings';
import GroupManage from './pages/groupmanage';


*/
import TaC from './components/tac';
import Privacy from './components/privacy';
import {Page} from './components/pageelements';
import Footer from './components/footer';

import {useGroups} from './store/groups';

// link in the CSS just to have the bundler pick it up
import './styles/index.scss'


// @ts-ignore - JSON importing does all kinds of weird errors.  Ignore them.
import ServiceExports from '../../service/exports.json';

Amplify.configure({
	Auth: {
		region: 'us-west-2',
		userPoolId: ServiceExports.userPoolId,
		userPoolWebClientId: ServiceExports.userPoolWebClientId
	}	
});

function RouterWrapper(props) {
	const groups = useGroups();
	var routes:any = [
		{path: '/', component: Main},
		{path: '/register', component: Register},
		{path: '/groups', component: Groups},
		{path: '/tac', component: Legal, props: {component: TaC}},
		{path: '/privacy', component: Legal, props: {component: Privacy}},
		{path: '/tifo', component: Tifoizer}
		/*
		{path: '/verified', component: Verified},
		{path: '/forgot', component: Forgot},
		{path: '/groups/:group', component: Group},
		*/
	];
	groups.forEach(group => {
		routes.push({path: `/${group.groupId.toLowerCase()}`, component: Group, props: {groupId:group.groupId}});
	});

	var Restricted = null;
	/*
	if(this.props.user && !checkPermissions(this.props.user, 'verified')) {
		Restricted = Verification;
		routes.push({path: '/verify', component: Verification});
	}
	if (this.props.user) {
		routes.push({path: '/profile', component: UserProfile});
		routes.push({path: '/groups/:group/settings', component: Restricted || GroupSettings});
		routes.push({path: '/groups/:group/manage', component: Restricted || GroupManage});
	}
	// Add support for direct group links last
	routes.push({path: '/:group', component: Group})
	if (this.props.user) {
		routes.push({path: ':group/settings', component: Restricted || GroupSettings});
		routes.push({path: ':group/manage', component: Restricted || GroupManage});
	}
	*/
	return <Router>
		<Navbar/>
		<Page>
			<Routes>
				{routes.map((route, iRoute) => {
					return <Route key={iRoute} path={route.path}
						element={<route.component {...route.props}/>}
					/>
				})}
				<Route path="*" element={<Main/>}/>
			</Routes>
		</Page>
		<Footer/>
	</Router>
}

const AppContainer = document.querySelector('#app');
ReactDOM.render(<RouterWrapper/>, AppContainer);


