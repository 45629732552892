import * as React from 'react';

export default function AsyncButton(props) {
    var spinner = null;
    if(props.working) {
        spinner = <><i className="icon fa fa-spinner fa-spin"></i>&nbsp;</>;
    }
    var label = props.children || 'Ok';
    if(props.completed && props.completedLabel) {
        label = this.props.completedLabel;
    }
    return <>
        <button className={'button ' + props.className} disabled={props.disabled || props.completed || props.working} onClick={(e) => props.onClick(e)}>{spinner}{label}</button>
    </>
}