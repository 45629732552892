import * as React from 'react';

export function Card(props) {
    return <div className="card">
        {props.header &&
            <div className="card-header">
                <div className="card-header-title subtitle">{props.header}</div>
            </div>
        }
        <div className="card-content content">
            {props.children}
        </div>
    </div>
}

export function Columns(props) {
    return <div className={'columns ' + (props.className || '')}>{props.children}</div>
}

export function Column(props) {
    var classes = 'column';
    if(props.is) {
        classes += ' is-' + props.is;
    }
    classes += ' ' + (props.className || '');
    return <div className={classes}>{props.children}</div>
}

export function Content(props) {
    var className = props.className || '';
    delete props.className;
    return <div className={'content' + ' ' + className} {...props}>{props.children}</div>
}

export function Title(props) {
    return <div className={'title ' + (props.className || '')}>{props.children}</div>
}

export function Subtitle(props) {
    return <div className={'subtitle ' + (props.className || '')}>{props.children}</div>
}

export function Section(props) {
    return <section className={'section ' + (props.className || '')}>{props.children}</section>
}

export function Button(props) {
    var newProps = {...props};
    delete newProps.className;
    return <button className={'button ' + (props.className || '')} {...newProps}>{props.children}</button>
}

export function Tile(props) {
    return <div className={'tile ' + (props.className || '')}>{props.children}</div>
}

export function Box(props) {
    return <div className={'box ' + (props.className || '')}>{props.children}</div>
}


export function Media(props) {
    return <div className="media">
        {props.left &&
            <div className="media-left">
                {props.left}
            </div>
        }
        <div className="media-content">
            {props.children}
        </div>
        {props.right &&
            <div className="media-right">
                {props.right}
            </div>
        }
    </div>
}

export function Icon(props) {
    return <i className="icon has-text-warning">{props.children}</i>
}